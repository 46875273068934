<template>
  <div class="update-product-component-group">
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin nhóm sản phẩm linh kiện'">
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <!-- Input session -->
              <b-container class="bv-example-row">
                <b-row>
                  <b-col cols="6">
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          id="input-group-name"
                          label="Tên nhóm"
                          label-for="input-name"
                          label-align-md="left"
                          class="required-control"
                        >
                          <b-form-input
                            size="sm"
                            id="input-name"
                            v-model="$v.mainModel.name.$model"
                            placeholder="Nhập tên"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="Mô tả">
                          <b-form-textarea
                            id="input-description"
                            v-model="mainModel.description"
                            placeholder="Nhập mô tả"
                            rows="5"
                            :max-length="255"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <h4>Danh sách sản phẩm/ nhóm sản phẩm</h4>
                <div class="separator separator-dashed my-5"></div>
                <b-row>
                  <b-form-group
                    id="input-group-search"
                    class="col-md-12 mt-4 pr-0"
                  >
                    <label for="input-code">Tìm sản phẩm/ nhóm sản phẩm:</label>
                    <b-col md="12" class="row pr-0">
                      <b-col md="2" class="p-0">
                        <b-form-select
                          class="select-style"
                          id="select-product"
                          v-model="selectTypeSearch"
                          :options="listTypeSearch"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          @change="onChangeTypeSearch"
                        ></b-form-select>
                      </b-col>
                      <b-col md="2" class="p-0">
                        <b-form-select
                          class="select-style"
                          id="select-product"
                          v-model="selectTypeOfProduct"
                          :options="listTypeOfProduct"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          @change="debounceInputFor"
                        ></b-form-select>
                      </b-col>
                      <b-col md="6" class="p-0">
                        <Autosuggest
                          :model="searchProductFor"
                          :suggestions="filteredOptionsProductFor"
                          :placeholder="'tên sản phẩm'"
                          :limit="10"
                          @select="onSelectedProductFor"
                          @change="onInputChangeProductFor"
                        />
                      </b-col>

                      <b-col md="2" class="pr-0">
                        <div class="d-flex justify-content-end">
                          <button
                            type="button"
                            class="btn btn-secondary font-weight-bolder btn-sm"
                            @click="showModalImportProduct"
                          >
                            <i
                              style="font-size: 1rem"
                              class="far fa-file-excel"
                            ></i
                            >Nhập SP từ excel
                          </button>
                        </div>
                      </b-col>
                    </b-col>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-col cols="12" md="12">
                    <div>
                      <b-table
                        id="my-table-main"
                        class="table-bordered table-hover col-md-12"
                        :fields="fields"
                        :items="mainModel.details"
                        v-if="renderComponent"
                        :per-page="10"
                        :current-page="currentPageMain"
                      >
                        <template v-slot:cell(type)="row">
                          <span v-if="row.item.type === 1">Sản phẩm</span>
                          <span v-if="row.item.type === 2">Nhóm sản phẩm</span>
                        </template>
                        <template v-slot:cell(actions)="row">
                          <v-icon
                            small
                            class="text-danger"
                            @click="showDeleteAlert(row.item)"
                            v-b-tooltip
                            title="Xóa"
                            >mdi-delete</v-icon
                          >
                        </template>
                      </b-table>
                      <b-pagination
                        v-show="mainModel.details.length > 10"
                        v-model="currentPageMain"
                        :total-rows="rowsMain"
                        :per-page="10"
                        aria-controls="my-table-main"
                        align="right"
                      ></b-pagination>
                    </div>
                  </b-col>
                  <b-col cols="6" md="4"></b-col>
                </b-row>
              </b-container>
            </b-form>
            <b-modal
              ref="import-product-modal"
              hide-footer
              title="Thêm sản phẩm/ nhóm sản phẩm từ Excel"
              id="modal-prevent-closing"
              size="lg"
            >
              <b-row>
                <b-col>
                  <div class="form-group">
                    <span>
                      <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                      đúng format, hoặc có thể tải ở
                      <a :href="urlExcel">đây</a>
                    </span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span> <b>Bước 2:</b> Tiến hành Import </span>
                  <div style="padding-top: 15px">
                    <b-form-file
                      placeholder="Hãy chọn một tập tin"
                      @change="selectFile"
                      v-model="file"
                      ref="file-input"
                      class="mb-2"
                    ></b-form-file>
                  </div>
                </b-col>
              </b-row>
              <b-container
                class="mt-4 pl-0 pr-0"
                v-show="importItems.length > 0"
              >
                <h5>
                  Danh sách sản phẩm từ file excel( Tổng:
                  {{ importItems.length }} sản phẩm)
                </h5>
                <b-table
                  id="my-table"
                  class="myTable"
                  responsive
                  bordered
                  hover
                  :fields="fieldImport"
                  :items="importItems"
                  :per-page="10"
                  :current-page="currentPage"
                >
                  <template v-slot:cell(type)="row">
                    <span v-if="row.item.type === 1">Sản phẩm</span>
                    <span v-if="row.item.type === 2">Nhóm sản phẩm</span>
                  </template>
                </b-table>
                <b-pagination
                  v-show="importItems.length > 10"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="10"
                  aria-controls="my-table"
                  align="right"
                ></b-pagination>
              </b-container>
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="validData"
                >Lưu</b-button
              >
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                @click="hideModalImportProduct"
                >Hủy</b-button
              >
            </b-modal>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <b-container class="bv-example-row">
              <b-row>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  type="submit"
                  @click="onSubmit"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="onBack"
                  >Hủy</b-button
                >
                <b-col></b-col>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
            </b-container>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { getToastInstance } from '@/utils/toastHelper';
import _ from 'lodash';
import decounce from 'debounce';
import {
  TIME_TRIGGER,
  URL_IMPORT_REWARD_PRODUCT_CATEGORY,
} from '@/utils/constants';
import xlsx from 'xlsx';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  mixins: [validationMixin],
  data() {
    return {
      renderComponent: true,
      mainModel: {
        id: 0,
        name: null,
        description: null,
        details: [],
      },
      fields: [
        {
          key: 'type',
          label: 'Loại',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'code',
          label: 'Mã',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '30%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'name',
          label: 'Tên',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '45%',
          },
          tdClass: 'text-left',
        },

        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      filteredOptionsProductFor: [],
      optionsProductFor: [
        {
          data: [],
        },
      ],
      listProduct: [],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProductFor: '',
      listTypeSearch: [
        {
          id: 1,
          name: 'Sản phẩm IMEI',
        },
        {
          id: 2,
          name: 'Sản phẩm',
        },
        {
          id: 3,
          name: 'Nhóm sản phẩm',
        },
      ],
      selectTypeSearch: 1,
      listTypeOfProduct: [
        {
          id: 0,
          name: 'Tất cả sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm cha',
        },
        {
          id: 2,
          name: 'Sản phẩm con',
        },
        {
          id: 3,
          name: 'Sản phẩm độc lập',
        },
        {
          id: 4,
          name: 'Sản phẩm cha + độc lập',
        },
        {
          id: 5,
          name: 'Sản phẩm con + độc lập',
        },
      ],
      selectTypeOfProduct: 0,
      isSearching: false,
      importItems: [],
      file: null,
      excellist: [],
      fieldImport: [
        {
          key: 'type',
          label: 'Loại',
        },
        {
          key: 'code',
          label: 'Mã',
        },
        {
          key: 'name',
          label: 'Tên',
        },
      ],
      currentPage: 1,
      urlExcel: URL_IMPORT_REWARD_PRODUCT_CATEGORY,
      rewardProductCategoryId: null,
      currentPageMain: 1,
    };
  },
  validations: {
    mainModel: {
      name: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    Autosuggest
  },
  computed: {
    rows() {
      return this.importItems.length;
    },
    rowsMain() {
      return this.mainModel.details.length;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nhóm sản phẩm', route: '/product-component-group' },
      {
        title: 'Cập nhật nhóm sản phẩm',
      },
    ]);
    this.rewardProductCategoryId = this.$route.query.id;
    this.getRewardProductCategoryById();
  },
  created() {},
  methods: {
    ...getToastInstance(),
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm/ nhóm sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm/ nhóm sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.mainModel.details = _.reject(
            this.mainModel.details,
            (product) => {
              return product.referenceId == item.referenceId;
            }
          );
        }
      });
    },
    fetchProductFor(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      this.optionsProductFor[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch/search-by-product-category?searchProduct=${textSearch}&typeSearch=${this.selectTypeSearch}&typeOfProduct=${this.selectTypeOfProduct}`
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProductFor[0].data.push(element);
        });
        this.filteredOptionsProductFor = [...this.optionsProductFor[0].data];
        this.isSearching = false;
        if (textSearch !== this.searchProductFor) {
          let nextTextSearch = this.searchProductFor;
          this.fetchProductFor(nextTextSearch);
        }
      });
    },
    onSelectedProductFor(option) {
      this.searchProductFor = '';
      let type = 1;
      if (this.selectTypeSearch === 3) {
        type = 2;
      } else if (this.selectTypeSearch === 4) {
        type = 3;
      }
      let item = {
        id: 0,
        referenceId: option.item.id,
        name: option.item.name,
        code: option.item.code,
        type: type,
      };
      this.mainModel.details.push(item);
    },
    onInputChangeProductFor(text) {
      this.searchProductFor = text;

      this.debounceInputFor();
    },
    onBack: function () {
      history.back();
    },
    onSubmit: function () {
      this.$v.mainModel.$touch();
      if (this.$v.mainModel.$anyError) {
        return;
      }

      let data = {
        id: this.rewardProductCategoryId,
        name: this.mainModel.name,
        description: this.mainModel.description,
        listProduct: this.mainModel.details,
      };
      ApiService.put('productComponentGroup', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            //this.$router.go(-1);
            history.back();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
    debounceInputFor: decounce(function () {
      let textSearch = this.searchProductFor;
      if (!this.isSearching) {
        this.fetchProductFor(textSearch);
      }
    }, TIME_TRIGGER),
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellist = excellist;
          this.excellist.map((element) => {
            let data = {
              name: element['Tên'] ? element['Tên'] : '',
              code: element['Mã'] ? element['Mã'] : '',
              type: element['Loại'] === 'Nhóm sản phẩm' ? 2 : 1,
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    showModalImportProduct() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-modal'].show();
    },
    hideModalImportProduct() {
      this.$refs['import-product-modal'].hide();
    },
    validData() {
      let data = {
        listProduct: this.importItems,
      };
      ApiService.setHeader();
      ApiService.post('productComponentGroup/valid-import-product', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            response.data.data.forEach((element) => {
              this.mainModel.details.push(element);
            });
            this.hideModalImportProduct();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err;
          this.makeToastFailure(message);
        });
    },
    onChangeTypeSearch() {
      this.selectTypeOfProduct = 0;
      if (this.selectTypeSearch === 3) {
        this.listTypeOfProduct = [
          {
            id: 0,
            name: 'Tất cả nhóm sản phẩm',
          },
          {
            id: 1,
            name: 'Nhóm sản phẩm cha',
          },
          {
            id: 2,
            name: 'Nhóm sản phẩm con',
          },
        ];
      } else {
        this.listTypeOfProduct = [
          {
            id: 0,
            name: 'Tất cả sản phẩm',
          },
          {
            id: 1,
            name: 'Sản phẩm cha',
          },
          {
            id: 2,
            name: 'Sản phẩm con',
          },
          {
            id: 3,
            name: 'Sản phẩm độc lập',
          },
          {
            id: 4,
            name: 'Sản phẩm cha + độc lập',
          },
          {
            id: 5,
            name: 'Sản phẩm con + độc lập',
          },
        ];
      }
      this.debounceInputFor();
    },
    getRewardProductCategoryById: function () {
      ApiService.setHeader();
      ApiService.get(`productComponentGroup/${this.rewardProductCategoryId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.$v.mainModel.name.$model = data.data.name;
            this.mainModel.description = data.data.description;
            this.mainModel.details = data.data.listProduct;
          } else {
            this.makeToastFailure('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
  },
};
</script>

<style lang="scss">
.update-product-component-group {
  .center-label label {
    margin-bottom: 0px;
  }
  .rounded {
    border-radius: 0 !important;
  }
  .form-group label {
    font-weight: 500;
  }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
}
</style>